import * as THREE from 'three';

// Create an empty scene
const canvas = document.getElementById('c-webgl');
const scene = new THREE.Scene();

// Create a basic perspective camera
const camera = new THREE.PerspectiveCamera( 45, canvas.parentElement.clientWidth/canvas.parentElement.clientHeight, 0.1, 1000 );
camera.position.z = 200;

// Create a renderer with Antialiasing
const renderer = new THREE.WebGLRenderer({canvas: canvas, antialias:true});

// Configure renderer clear color
renderer.setClearColor("#ffffff");

// Configure renderer size
function onresize() {
  renderer.setSize( canvas.parentElement.clientWidth, canvas.parentElement.clientHeight, false);
  camera.aspect = canvas.parentElement.clientWidth / canvas.parentElement.clientHeight;
  camera.updateProjectionMatrix();
};
window.onresize = function () {
  onresize();
};

// Create group
let rotationSpeed = 0.002;
const group = new THREE.Group();
scene.add( group );

// Create a cube
const r = 250;
const geometry = new THREE.BoxGeometry( r, r, r );
const cube = new THREE.Mesh( geometry );

// Create bounding box
const box = new THREE.BoxHelper( cube, 0x000000 );
box.material.blending = THREE.AdditiveBlending ;
box.material.transparent = true;
group.add( box );

// Create particles
const particleCount = 1000;
const pMaterial = new THREE.PointsMaterial( {
  color: 0x222222,
  size: 3,
  blending: THREE.SubtractiveBlending,
  transparent: true,
  sizeAttenuation: false
} );
const particles = new THREE.BufferGeometry();
const particlePositions = new Float32Array( particleCount * 3 );
const particleData = []

const rslim = r / 2;
const finalVelocity = 0.2;
const initialAcceleration = 0.01;
const finalAcceleration = 0.0001;
for ( let i = 0; i < particleCount; i++ ) {
  let xi = i * 3;
  let yi = i * 3 + 1;
  let zi = i * 3 + 2;

  particlePositions[xi] = Math.random() * r - r / 2;
  particlePositions[yi] = Math.random() * rslim - rslim / 2;
  particlePositions[zi] = Math.random() * r - r / 2;

  particleData.push({
    velocity: new THREE.Vector3(2 * Math.random() - 1, 2 * Math.random() - 1, 2 * Math.random() - 1),
    acceleration: new THREE.Vector3(
      2 * initialAcceleration * Math.random() - (initialAcceleration * .5), 
      2 * initialAcceleration * Math.random() - (initialAcceleration * .5), 
      2 * initialAcceleration * Math.random() - (initialAcceleration * .5)
    )
  });
}

particles.setDrawRange( 0, particleCount );
particles.setAttribute( 'position', new THREE.BufferAttribute( particlePositions, 3 ).setUsage( THREE.DynamicDrawUsage ) );

const pointCloud = new THREE.Points( particles, pMaterial );
group.add( pointCloud );

// Render Loop
function render() {
  requestAnimationFrame( render );

  // Spin bounding box
  group.rotation.y += rotationSpeed;

  for(let i=0; i<particleCount; i++) {
    let xi = i * 3;
    let yi = i * 3 + 1;
    let zi = i * 3 + 2;

    // Position by velocity
    particlePositions[xi] += particleData[i].velocity.x;
    particlePositions[yi] += particleData[i].velocity.y;
    particlePositions[zi] += particleData[i].velocity.z;

    // Increment with acceleration
    particleData[i].velocity.x += particleData[i].acceleration.x;
    particleData[i].velocity.y += particleData[i].acceleration.y;
    particleData[i].velocity.z += particleData[i].acceleration.z;

    // Reset
    if (particlePositions[ xi ] > r / 2 || particlePositions[ yi ] > r / 2 || particlePositions[ zi ] > r / 2) {
      particlePositions[xi] = Math.random() * r - r / 2;
      particlePositions[yi] = Math.random() * r - r / 2;
      particlePositions[zi] = Math.random() * r - r / 2;

      // Final with tendency to rise
      particleData[i].velocity = new THREE.Vector3(
        2 * finalVelocity * Math.random() - (finalVelocity * .5), 
        2 * finalVelocity * Math.random() - (finalVelocity * .5), 
        2 * finalVelocity * Math.random() - (finalVelocity * .5)
      );
      particleData[i].acceleration = new THREE.Vector3(
        2 * finalAcceleration * Math.random() - (finalAcceleration * .5), 
        1 * finalAcceleration * Math.random(),
        2 * finalAcceleration * Math.random() - (finalAcceleration * .5)
      );
    }
  }

  pointCloud.geometry.attributes.position.needsUpdate = true;

  // Render the scene
  renderer.render(scene, camera);
};

onresize();
render();
